let config: any = {};
if (process.env.NODE_ENV == 'development') {
    config = {
        "AUTH_URL": "http://simplicity/managerapi/login",
        "BASE_API_URL": "http://simplicity/managerapi/"
    };
} else {
    config = {
        "AUTH_URL": "https://rstarmanager.simpli-city.com/managerapi/login",
        "BASE_API_URL": "https://rstarmanagement.simpli-city.com/managerapi/"
    };
}
export default config;