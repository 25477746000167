import React, {useContext, useEffect, useState} from "react";
import {faEnvelope, faPhone, faArrowLeft, faUpload} from "@fortawesome/free-solid-svg-icons";
import EasyEdit, {Types} from 'react-easy-edit';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import useFetch from "../../hooks/useFetch";
import {Link, useNavigate} from "react-router-dom";
import {Btn} from "../PageComponents";
import {CurrentUserContext} from "../../contexts/currentUser";
import {isBoolean} from "util";

export const TicketInfo : React.FunctionComponent<any> = props => {
    let ticket = props.data;
    let [borderCl, setBorderCl] = useState<any>('');
    let [clockStatus, setClockStatus] = useState<boolean>(false);
    const [{responses}, updateField] = useFetch('updateField');
    const [clockResponse, fetchClock] = useFetch('toggleClock');
    const navigate = useNavigate();
    const [currentUserState, dispatch] = useContext(CurrentUserContext);

    useEffect(() => {
        if(!ticket || !currentUserState.currentUser) return;
        if(currentUserState.currentUser) setClockStatus(currentUserState.currentUser.clockIn != null);
        if(currentUserState.currentUser.clockIn && currentUserState.currentUser.clockIn.ticket != ticket.id) setClockStatus(false);
    }, [currentUserState.currentUser.clockIn, ticket])

    useEffect(() => {
        if(clockResponse.responses) {
            if(clockResponse.responses && clockResponse.responses['result'] == 'end') {
                currentUserState.currentUser.clockIn = null;
            } else if(clockResponse.responses && clockResponse.responses['result'] == 'start') {
                currentUserState.currentUser.clockIn = clockResponse.responses['data'];
            }
            dispatch({ type: "TOGGLE_CLOCK" })
        }
    }, [clockResponse.responses])

    useEffect(() => {
        if(ticket)
            switch(ticket.statusid) {
                case '1': setBorderCl("border-status1"); break;
                case '2': setBorderCl("border-status2"); break;
                case '3': setBorderCl("border-status3"); break;
                case '4': setBorderCl("border-status4"); break;
                case '5': setBorderCl("border-status5"); break;
            }
    }, [ticket])

    const quickSave = (field: string, data:any) => {
        updateField({
            method: 'POST',
            data: {'field' : field, 'data' : data, 'tid' : ticket.id}
        })
    }

    const toggleClockIn = () => {
        if(currentUserState.currentUser.clockIn && currentUserState.currentUser.clockIn.ticket != ticket.id) return false;
        var today = new Date();
        var date = today.toLocaleDateString();
        var time = today.toLocaleTimeString('en-US', { hour12: false });

        fetchClock({
            method: 'post',
            data: { date: date, time: time, task: ticket.id}
        });
    }

    const CustomDate = props => {
        const val = props.value || "";
        const parts = val.split("-");
        return (
            <div>
                {parts[1] + '/' + parts[2] + '/' + parts[0]}
            </div>
        );
    };

    return (
        <div>
            <div className="text-blue-900 p-1 mt-5 mb-3 text-left cursor-pointer" onClick={() => navigate(-1)}>
                <FontAwesomeIcon icon={faArrowLeft} /> All Tickets
            </div>
            {ticket &&
            <div>
                <div className={"bg-gray-50 max-w-screen-lg mx-auto mt-2 border-l-4 shadow-md p-2 " + borderCl}>
                    <div className="flex flex-row font-medium px-5 pt-5">
                        <div className="flex-1 text-left">{ticket.type}</div>
                        <div className="flex-initial">Ticket #{ticket.id}</div>
                    </div>
                    <div className="text-left mt-4 font-bold text-gray-400 px-5">
                        <EasyEdit
                            type="text"
                            value={ticket.title}
                            onSave={(val) => quickSave("title", val)}
                            cssClassPrefix = "w-full "
                            hideSaveButton
                            hideCancelButton
                            saveOnBlur
                        />
                    </div>
                    <div className="text-left mt-2 text-gray-400 px-5">
                        <EasyEdit
                            type = "textarea"
                            value={ticket.description}
                            onSave={(val) => quickSave("description", val)}
                            cssClassPrefix = "w-full "
                            hideSaveButton
                            hideCancelButton
                            saveOnBlur

                        />
                    </div>
                    <div className="text-left mt-4 font-bold text-gray-400 px-5">Submitted on {ticket.create_date} by <u className="text-black">{ticket.created_name}</u></div>
                    <div className="flex flex-row mt-2 text-gray-500 px-5 pb-5">
                        <div className="flex-1 text-left">
                            <div className="mb-1">Status: <div className="inline-block font-bold">
                                <EasyEdit
                                    type = "select"
                                    value={ticket.statusid}
                                    options={ticket.statusList}
                                    onSave={(val) => quickSave("status", val)}
                                    hideSaveButton
                                    hideCancelButton
                                    saveOnBlur
                                />
                            </div></div>
                            <div className="mb-1">Assigned to: <div className="inline-block font-bold">
                                <EasyEdit
                                    type = "select"
                                    value={ticket.assigned_to}
                                    options={ticket.employeeList}
                                    onSave={(val) => quickSave("assigned_to", val)}
                                    hideSaveButton
                                    hideCancelButton
                                    saveOnBlur
                                />
                            </div></div>
                            <div className="mb-1">Due on: <div className="inline-block font-bold">
                                <EasyEdit
                                    type = "date"
                                    value={ticket.due_date}
                                    onSave={(val) => quickSave("due_date", val)}
                                    displayComponent={<CustomDate />}
                                    hideSaveButton
                                    hideCancelButton
                                    saveOnBlur
                                />
                            </div></div>
                            <div className="mb-1">Priority: <div className="inline-block font-bold">
                                <EasyEdit
                                    type = "select"
                                    value={ticket.priorityid}
                                    options={ticket.priorityList}
                                    onSave={(val) => quickSave("priority", val)}
                                    hideSaveButton
                                    hideCancelButton
                                    saveOnBlur
                                />
                            </div></div>
                        </div>
                        <div className="flex-1 text-left">
                            <div className="mb-1">Property: <b>{ticket.property}</b></div>
                            <div className="mb-1">Unit: <b>{ticket.unit}</b></div>
                            {ticket.tenant_info && <div className="mb-1">Tenant: <Link to={"/tenant/"+ticket.tenant_info.id} className="text-blue-400 underline"><b>{ticket.tenant_info && ticket.tenant_info.first_name + ' ' + ticket.tenant_info.last_name}</b></Link></div>}
                            {ticket.tenant_info && <div className="mb-1">
                                <div className="inline-block cursor-pointer" onClick={() => window.location.href = 'mailto:'+ticket.tenant_info.email}><FontAwesomeIcon className="text-blue-400" icon={faEnvelope} />{ticket.tenant_info.email}</div>
                                <div className="inline-block cursor-pointer ml-2" onClick={() => window.location.href = 'tel:'+ticket.tenant_info.phone}><FontAwesomeIcon className="text-blue-400" icon={faPhone} />{ticket.tenant_info.phone}</div>
                            </div>}
                        </div>
                    </div>
                </div>
                <div className="flex flex-row mt-1">
                    <Btn className="flex-1"><FontAwesomeIcon icon={faUpload} /> Receipt</Btn>
                    <Btn className={"flex-1 " + (currentUserState.currentUser.clockIn && currentUserState.currentUser.clockIn.ticket != ticket.id ? "text-gray-400 bg-gray-100 cursor-auto" : "")} onClick={() => toggleClockIn()}>{clockStatus ? "Clock Out" : "Clock In"}</Btn>
                    <Btn className="flex-1">Reminder</Btn>
                </div>

            </div>}
        </div>
    )
}
