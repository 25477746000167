import React, {useContext, useEffect, useState} from "react";
import {Link, NavLink} from "react-router-dom";
import {CurrentUserContext} from "../contexts/currentUser";
import {faArrowLeft, faClock} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
export const PageWrapper : React.FunctionComponent<any> = props => (
    <div className="w-full h-full">
        { props.children }
    </div>
);

export const PageContainer : React.FunctionComponent<any> = props => (
    <div className="w-full h-screen box-border">
        { props.children }
    </div>
);

export const Btn : React.FunctionComponent<any> = props => (
    <div onClick = {props.onClick} className={"p-2 bg-slate-200 cursor-pointer m-1 select-none rounded " + props.className}>
        { props.children }
    </div>
);

const logo = require("../assets/logo.png");
export const PageHeader : React.FunctionComponent<any> = (props) => {
    const [currentUserState] = useContext(CurrentUserContext);
    const [seconds, setSeconds] = useState(0);
    const [isActive, setIsActive] = useState(false);
    let interval:any = null;
    useEffect(() => {
        if (isActive) {
            interval = setInterval(() => {
                setSeconds(seconds => seconds + 1);
            }, 1000);
        } else if (!isActive && seconds !== 0) {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [isActive, seconds]);

    useEffect(() => {
        if(currentUserState.currentUser && currentUserState.currentUser.clockIn && !isActive) {
            var startDate = new Date(currentUserState.currentUser.clockIn['start']);
            var endDate   = new Date();
            var seconds = Math.ceil((endDate.getTime() - startDate.getTime()) / 1000);
            setSeconds(seconds);
            setIsActive(true);
        } else
        if(currentUserState.currentUser && !currentUserState.currentUser.clockIn && isActive) {
            setIsActive(false);
            clearInterval(interval);
        }
    }, [currentUserState.currentUser ? currentUserState.currentUser.clockIn : null]);
    return(
    <div className="w-full fixed top-0 left-0 bg-white drop-shadow-md z-100">
        <NavLink to={"/"}><img src={logo} className="h-9 m-2 float-left"></img></NavLink>
        {props.name &&
        <div className="float-right mt-3 mr-3 p-2 bg-gray-200 rounded-lg leading-3 text-base h-[30px]">Welcome, <span
            className="font-medium">{props.name}</span></div>}
        {currentUserState.currentUser && currentUserState.currentUser.clockIn &&
        <NavLink to={"/ticket/"+currentUserState.currentUser.clockIn['ticket']}><div className="select-none float-right mt-3 mr-3 p-2 bg-gray-200 rounded-lg leading-3 text-base">
             <FontAwesomeIcon className="mr-1 text-sm" icon={faClock} />
            {('0' + Math.floor(seconds / 3600)).slice(-2) + ":" + ('0' + Math.floor(seconds % 3600 / 60)).slice(-2) + ":" + ('0' + seconds % 3600 % 60).slice(-2)}
        </div></NavLink>}

        {props.children}
    </div>);
}

export const PageContent : React.FunctionComponent<any> = props => (
    <div className={"w-full md:w-5/6 lg:w-2/3 xl:1/2 m-auto h-full p-1 " + (props.header ? "pt-12" : "")}>
        { props.children }
    </div>
);

export const Alert : React.FunctionComponent<any> = props => {
    let c = props.type ? 'bg-green-100 rounded-lg py-5 px-6 mb-4 text-base text-green-700 mb-3' : 'bg-red-100 rounded-lg py-5 px-6 mb-4 text-base text-red-700 mb-3';
    return(
        <div className={c} role="alert">
            { props.children }
        </div>
    )
};

/*export const PageContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;    
`;

export const PageWrap = styled.div`
  width: 100%;
  height: 100%;
  margin:auto;
  overflow-y: scroll;
  & > div { max-width:1200px; margin:auto; }
`;

export const PageContent = styled.div`
  width: 100%;
  position: relative;
  top: -25px;  
`;*/